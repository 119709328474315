<template>
  <div>

    <swiper ref="filterSwiper" :options="swiperOption" role="tablist">
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2023</div>
              <span class="month">02</span> 
              <span class="txt">[주]로완 사업 제휴</span>
              <div class="his01"><img src="../../assets/img/bg_main0604.png"/></div>
            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2022</div>
              <span class="month">08</span> 
              <span class="txt">[그로비 교육]'슈퍼브이'사업 제휴</span>
              <div class="his01"><img src="../../assets/img/bg_main0603.png"/></div>
            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2021</div>
              <span class="month">12</span> 
              <span class="txt">테라타워 인프라 확장, 디바이스 전용 창고 구축 및 라인 확장 진행 </span>
              <div class="his01"><img src="../../assets/img/bg_main0601.png"/></div>

            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2020</div>
              <span class="month">12</span> 
              <span class="txt">흥덕 → 테라타워 오피스 이전, 업무 자동화를 통한 사업 확장 </span>
                <div class="his01"><img src="../../assets/img/bg_main0102.png"/></div>

            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2019</div>
              <div><span class="month">04</span> <span class="txt">아이쿱클리닉 사업 제휴 </span></div>
              <div><span class="month">04</span> <span class="txt">㈜와이즈캠프닷컴 사업제휴</span></div>
              <!-- <div class="his01"><img src="../../assets/img/bn0707.png"/></div> -->
            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
               <div class="year">2018</div>
              <span class="month">06</span> <span class="txt">청담어학원 사업제휴</span>
              <span class="month">05</span> <span class="txt">유엔젤㈜에듀테크 ‘TOMO’’ 사업 제휴</span>
              <span class="month">03</span> <span class="txt">매쓰플랫 사업제휴</span>
              <!-- <div class="his01"><img src="../../assets/img/bn0706.png"/></div> -->
              <!-- <div class="his01"><img src="../../assets/img/bn0709.png"/></div> -->
            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
           <div class="year">2017</div>
              <span class="month">05</span> <span class="txt">웅진 ‘웅진북클립’ 사업 제휴</span>
              <span class="month">04</span> <span class="txt">삼성전자 B2B STAR파트너 대리점 선정</span>
              <span class="month">03</span> <span class="txt">삼성전자 태블릿 관리 공식 솔루션 상품화</span>
              <span class="month">02</span> <span class="txt">해법에듀 ‘해법영어, 해법수학’ 사업 제휴</span>
              <!-- <div class="his02"><img src="../../assets/img/bg_main0602.png"/></div> -->
            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2016</div>
              <span class="month">10</span> <span class="txt">대교 태블릿 케이스 공급 </span>
              <span class="month">06</span> <span class="txt">메가스터디 ‘엠베스트’ 사업 제휴</span>
               <!-- <div class="his02"><img src="../../assets/img/bg_main0306.png"/></div> -->

            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2015</div>
              <span class="month">08</span> <span class="txt">새한음반, ‘윤선생’ 사업 제휴</span>
              <!-- <div class="his02"><img src="../../assets/img/bn0705.png"/></div> -->

            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide role="tab">
        <div>
          <ul>
            <li>
              <div class="year">2014</div>
              <span class="month">12</span> <span class="txt">천재교육 ‘밀크티‘ 사업 제휴</span>
              <!-- <div class="his02"><img src="../../assets/img/bn0704.png"/></div> -->

            </li>
          </ul>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

// import '/src/assets/css/swiper.css'

export default {
  name: 'HistorySwiper',
  data () {
    const _vm = this
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 6,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: true,
        centerInsufficientSlides: true,
        on: {
          click: function () {
            _vm.slideMoveTo()
            _vm.activeTab()
          },
          tap: function () {
            _vm.slideMoveTo()
            _vm.activeTab()
          },
          resize: function () {
            this.allowTouchMove = !_vm.isOverview
          }
        }
      }
    }
  },
  methods: {
    swiperInit: function () {
      this.swiper.allowTouchMove = !this.isOverview
      this.activeTab()
    },
    activeTab: function (swiper) {
      swiper = swiper || this.swiper
      if (swiper.hasOwnProperty('clickedSlide') && !swiper.clickedSlide) return

      const slideSelector = `.${swiper.params.slideClass}`
      const selectedEl = swiper.clickedSlide || swiper.slides[swiper.params.initialSlide]
      const swiperArr = document.querySelectorAll(slideSelector)
      Array.from(swiperArr).forEach((el) => {
        el.setAttribute('aria-selected', 'false')
        selectedEl.setAttribute('aria-selected', 'true')
      })
    },
    slideMoveTo: function (swiper = this.swiper) {
      if (!swiper.clickedSlide) return

      const activeIndex = swiper.clickedIndex
      swiper.slideTo(activeIndex)
    }
  },
  computed: {
    swiper: function () {
      return this.$refs.filterSwiper.swiper
    },
    isOverview: function () {
      return window.innerWidth >= this.swiper.virtualSize
    }
  },
  mounted () {
    this.swiperInit()
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>
<style lang="scss" scoped>
.swiper-container {
  padding: 1% 0;
  // width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
    //  background: linear-gradient(90deg, #fff -20.19%, rgba(255, 255, 255, 0.8) 18.31%, rgba(255, 255, 255, 0) 75%);

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 0;
    // width: 100%;
    // height: 100%;
    z-index: 10;
    // content: "";
    
  }
  &:before {
    left: 0;
    // background: linear-gradient(90deg, #fff -20.19%, rgba(255, 255, 255, 0.8) 18.31%, rgba(255, 255, 255, 0) 75%);
  }
  &:after {
    right: 0;
    // background: linear-gradient(270deg, #fff -20.19%, rgba(255, 255, 255, 0.8) 18.31%, rgba(255, 255, 255, 0) 75%);
  }
  .swiper-wrapper {
    .swiper-slide {
    width: 16%;
    margin: 0 1%;
    padding: 2em 2em;
    font-size: 14px;
    line-height: 2.25em;
    text-align: left;
    border-radius: 0.9em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    box-shadow: 0px 6px 25px #00000017;
    background: #fffc;
      &[aria-selected="true"] {
        // color: #fff;
        // background: #000;
      }
    }
  }
   .swiper-slide:hover{
    transform:scale(1.05);
    transition: 1s;
    background: #fff;
   }
}
</style>
